/* tslint:disable:no-import-side-effect */
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

import * as firebase from "firebase/app";

import * as Sentry from "@sentry/browser";

/* tslint:enable:no-import-side-effect */
import { CardData } from "./CardData";
import cloudFunctionProxy from "./cloudFunctionProxy";
import { CoachMarkID } from "./coachMarks/coachMarkListing";
import { EssayID, orderedEssayIDs } from "./essays/essayMetadata";
import getConditionForUserID from "./experiments/getConditionForUserID";
import {
  ExperimentCondition,
  ExperimentName,
  UserExperimentConditions,
} from "./experiments/types";
import { AggregateSessionRecord } from "./progress";
import { ReviewIntervalMilliseconds, ReviewMarking } from "./spacedRepetition";
import { DeviceType, ReviewMarkingMethod } from "./types/marking";
import { NotificationState } from "./types/notificationState";
import isTouch from "./util/isTouch";
import getEnrolledConditionForUser from "./experiments/getEnrolledConditionForUser";
import { isViewingForSSRSnapshot } from "./util/isViewingForSSRSnapshot";
import getConditionOrEnrollUser from "./experiments/getConditionOrEnrollUser";
import getDueCardIDs from "./util/getDueCardIDs";
import unreachableCaseError from "./util/unreachableCaseError";

type UserID = string;

export interface UserRecord {
  creationTimestamp: firebase.firestore.Timestamp;
  registrationTimestamp: firebase.firestore.Timestamp;
  lastMarkingTimestamp?: firebase.firestore.Timestamp;
  accessCode?: string;
  memo?: string;
  phoneNumber?: string;
  email?: string;
  cardCount?: number;
  notificationState?: NotificationState;
  cards?: CardStates;
  timeZoneOffsetMinutes?: number;
  unsubscribeFromNotifications?: boolean;
  lastReviewSessionIndex?: number; // 0-indexed; note that right now, the `compliance` view's `sessionNumber` is 1-indexed
  lastCardCollectedEssayName?: EssayID;
  cardsCompletedInCurrentSession?: number;
  experimentConditions?: UserExperimentConditions;
  seenCoachMarks?: CoachMarkID[];
  efficacy2EnrollmentTimestamp?: firebase.firestore.Timestamp;
  efficacy2TestReviewSessionState?:
    | "notified"
    | "started"
    | "complete"
    | "complete2";
}

export interface CardState {
  dueTime: firebase.firestore.Timestamp;
  interval: ReviewIntervalMilliseconds;
  bestInterval: ReviewIntervalMilliseconds | null;
  needsRetry?: boolean;
  reviewCount: number;
  longestAttemptedInterval: ReviewIntervalMilliseconds;
  orderSeed?: number;
}
export type CardStates = { [key: string]: CardState };

interface MarkingRecord {
  cardID: string;
  promptIndex: number | null;
  reviewMarking: ReviewMarking;
  newCardStateOrderSeed: number;
  essayName: string | null;
  essayVersion: number | null;
  dueTimestamp: firebase.firestore.Timestamp | null;
  sessionID: number | null;
  reviewDate: Date;
  deviceType: DeviceType | null;
  reviewMarkingMethod: ReviewMarkingMethod | null;
}

const _markCard = async (
  markingRecord: MarkingRecord,
  userID: UserID | null,
) => {
  if (userID) {
    const markCardCallable = firebase.functions().httpsCallable("markCard");
    return markCardCallable({
      ...markingRecord,
      userID,
      reviewTimestamp: firebase.firestore.Timestamp.fromDate(
        markingRecord.reviewDate,
      ),
      timeZoneOffsetMinutes: new Date().getTimezoneOffset(),
    }).catch(error => {
      console.log(
        "Marking error",
        error.code,
        error.message,
        error.details,
        markingRecord,
        userID,
      );
    });
  } else {
    console.log(
      `Marking ${markingRecord.cardID} in-memory only because user is not yet logged in`,
    );
    pendingMarkingRecords.push(markingRecord);
    return Promise.resolve();
  }
};

let hasReceivedAnyAuthNotifications = false;
let pendingExperimentConditions: UserExperimentConditions = {};
let pendingMarkingRecords: MarkingRecord[] = [];
let pendingSeenCoachMarks: Set<CoachMarkID> = new Set();
const userChangeSubscriptions: Set<(userState: UserState) => void> = new Set();
let userChangeEventIsPending: boolean = false;

export type UserState = "pending" | "anonymous" | "registered";

const _stateForUser = (user: firebase.User | null): UserState =>
  user
    ? user.isAnonymous
      ? "anonymous"
      : "registered"
    : hasReceivedAnyAuthNotifications
    ? "anonymous"
    : "pending";

let hasInitializedDB = false;
let unsubscribeAuthStateChange: firebase.Unsubscribe | null = null;

async function flushPendingInteractions(
  userID: string,
): Promise<{ didFlush: boolean }> {
  let promises: Promise<any>[] = [];
  if (Object.keys(pendingExperimentConditions).length > 0) {
    console.log(
      "Flushing pending experiment enrollments",
      pendingExperimentConditions,
    );
    promises.push(
      ...Object.keys(pendingExperimentConditions).map(async key => {
        const experimentName = key as ExperimentName;
        const condition = pendingExperimentConditions[
          experimentName
        ]! as ExperimentCondition<typeof experimentName>;
        const result = await cloudFunctionProxy.enrollInExperiment({
          experimentName,
          condition,
        });
        console.log(`Enrolled in ${experimentName}/${condition}: ${result}`);
      }),
    );
    pendingExperimentConditions = {};
  }

  if (pendingSeenCoachMarks.size > 0) {
    console.log(
      `Flushing coach marks ${[...pendingSeenCoachMarks].join(
        ", ",
      )} to the server for newly registered user`,
    );
    promises.push(
      ...[...pendingSeenCoachMarks].map(async coachMarkID =>
        cloudFunctionProxy.markCoachMarkSeen({
          coachMark: coachMarkID,
        }),
      ),
    );
    pendingSeenCoachMarks = new Set();
  }

  let didFlush = false;
  if (promises.length > 0) {
    await Promise.all(promises);
    console.log("Flushed coach marks and experiment conditions.");
    didFlush = true;
  }

  if (pendingMarkingRecords.length > 0) {
    didFlush = true;
    console.log(
      `Flushing ${pendingMarkingRecords.length} card markings to the server for newly registered user`,
    );

    await Promise.all(
      pendingMarkingRecords.map(pendingMarkingRecord =>
        _markCard(pendingMarkingRecord, userID),
      ),
    );
    console.log("Flushed card markings.");
    pendingMarkingRecords = [];
  }

  return { didFlush };
}

const _initializeDBIfNecessary = async () => {
  if (hasInitializedDB) {
    return;
  }
  hasInitializedDB = true;

  const config = {
    // Note: it's OK to commit this API key to a repo; this is served to clients in JS from the web server.
    apiKey: "AIzaSyCYX7lVmYfnk0bAc0JlDeaU2h-fWipf0yw",
    authDomain: "metabook-qcc.firebaseapp.com",
    databaseURL: "https://metabook-qcc.firebaseio.com",
    projectId: "metabook-qcc",
  };
  firebase.initializeApp(config);

  if (typeof window !== "undefined") {
    try {
      await _getDatabase().enablePersistence({ synchronizeTabs: true });
    } catch (error) {
      console.log("Couldn't enable persistence: ", error);
    }
  }

  unsubscribeAuthStateChange = firebase
    .auth()
    .onAuthStateChanged(async user => {
      hasReceivedAnyAuthNotifications = true;
      userChangeEventIsPending = true;
      const newUserState = _stateForUser(user);
      if (user) {
        console.log("Signed in: ", user.uid, user.email);
        Sentry.setUser({ id: user.uid });

        let needsFlush = true;
        while (needsFlush) {
          needsFlush = (await flushPendingInteractions(user.uid)).didFlush;
        }
      } else {
        console.log("User has no account");
      }
      userChangeSubscriptions.forEach(s => s(newUserState));
      userChangeEventIsPending = false;
      _setPriorSessionUserID(user?.uid || null);
    });
};

const _getDatabase = () => {
  return firebase.firestore();
};

const _getUserReference = (userID: UserID) =>
  _getDatabase()
    .collection("users")
    .doc(userID);

function _getCardsReference(userID: UserID) {
  return _getUserReference(userID).collection("cardStates");
}

export const initializeDB = _initializeDBIfNecessary;

const _getCurrentUserOrThrow = (): firebase.User => {
  const currentUser = firebase.auth().currentUser;
  if (!currentUser) {
    throw new Error("Not currently signed in.");
  }
  return currentUser;
};

const _cardStatesFromQuerySnapshot = (
  snapshot: firebase.firestore.QuerySnapshot,
): CardStates => {
  const cardStates: CardStates = {};
  snapshot.docs.forEach(doc => (cardStates[doc.id] = doc.data() as CardState));
  return cardStates;
};

export const fetchCardStates = async (
  userID: string = _getCurrentUserOrThrow().uid,
  getOptions?: firebase.firestore.GetOptions,
): Promise<CardStates> => {
  const snapshot = await _getCardsReference(userID).get(getOptions);
  return _cardStatesFromQuerySnapshot(snapshot);
};

const localStoragePriorSessionUserIDKey = "QuantumCountryPriorSessionUserID";
function _getPriorSessionUserID(): string | null {
  if (isViewingForSSRSnapshot() || typeof window === "undefined") {
    return null;
  } else {
    try {
      return window.localStorage.getItem(localStoragePriorSessionUserIDKey);
    } catch (error) {
      console.error(`Local storage operation failed: ${error}`);
      return null;
    }
  }
}

function _setPriorSessionUserID(userID: string | null) {
  if (!isViewingForSSRSnapshot() && typeof window !== "undefined") {
    try {
      if (userID) {
        window.localStorage.setItem(localStoragePriorSessionUserIDKey, userID);
      } else {
        window.localStorage.removeItem(localStoragePriorSessionUserIDKey);
      }
    } catch (error) {
      console.error(`Local storage operation failed: ${error}`);
    }
  }
}

// TODO remove experiment
export async function getCachedReviewData(): Promise<{
  cardStates: CardStates;
  dueCardIDs: string[];
  userRecord: UserRecord | null;
}> {
  let userID = firebase.auth().currentUser?.uid || null;
  if (userID === null) {
    userID = _getPriorSessionUserID();
  }
  if (userID === null) {
    return { cardStates: {}, dueCardIDs: [], userRecord: null };
  }
  try {
    const cardStates = await fetchCardStates(userID, { source: "cache" });
    const userRecord = await fetchUserRecord(userID, { source: "cache" });

    const dueCardIDs = cardStates
      ? getDueCardIDs({
          cardStates,
          cardsCompletedInCurrentSession: 0,
          reviewSessionIndex: 2,
          timestampMillis: Date.now(),
        })
      : [];
    return { cardStates, dueCardIDs, userRecord };
  } catch (e) {
    console.log("Couldn't retrieve cached data", e);
    return { cardStates: {}, dueCardIDs: [], userRecord: null };
  }
}

export const fetchUserRecord = async (
  userID: string = _getCurrentUserOrThrow().uid,
  getOptions?: firebase.firestore.GetOptions,
): Promise<UserRecord> => {
  const snapshot = await _getUserReference(_getCurrentUserOrThrow().uid).get(
    getOptions,
  );
  // TODO: this is a pretty rough hack. There's a race on user creation where sometimes the user record doesn't exist yet. This hack will make a few properties undefined that should never be undefined. But it's probably OK for now.
  return (snapshot.data() || {}) as UserRecord;
};

export const getCurrentUserState = () =>
  _stateForUser(firebase.auth().currentUser);

export const subscribeToUserChanges = (
  onChange: (userState: UserState) => void,
): (() => void) => {
  if (isViewingForSSRSnapshot()) {
    return () => {
      return;
    }; // Intentional no-op.
  } else {
    userChangeSubscriptions.add(onChange);
    if (!userChangeEventIsPending) {
      onChange(getCurrentUserState());
    }
    return () => userChangeSubscriptions.delete(onChange);
  }
};

export const blessCurrentUserWithAccessCode = async (accessCode: string) => {
  const userID = _getCurrentUserOrThrow().uid;
  try {
    const { success } = (
      await firebase.functions().httpsCallable("blessWithAccessCode")({
        targetUserID: userID,
        accessCode,
      })
    ).data as { success: boolean };
    return success;
  } catch (e) {
    console.error("Error: ", e);
    return false;
  }
};

export const getCurrentUserIsBlessed = async () => {
  return (await firebase.functions().httpsCallable("currentUserIsBlessed")())
    .data;
};

export function getExperimentCondition<EN extends ExperimentName>(
  experimentName: EN,
  userRecord: UserRecord | null,
  userState: UserState,
  enrollIfNecessary: boolean,
): ExperimentCondition<EN> | null {
  switch (userState) {
    case "anonymous":
      const existingCondition = pendingExperimentConditions[experimentName];
      if (existingCondition) {
        return existingCondition as ExperimentCondition<EN>; // Shouldn't have to help the type system out like this. Bluh.
      } else if (enrollIfNecessary) {
        // A pretty goofy way of getting an anonymous assignment!
        const condition = getConditionForUserID(
          Math.random().toString(),
          experimentName,
        );
        pendingExperimentConditions[
          experimentName
        ] = condition as typeof pendingExperimentConditions[EN]; // Shouldn't have to help the type system out like this. Bluh.

        console.log(
          `Speculatively enrolling anonymous user in ${experimentName} under condition ${condition}`,
        );
        return condition;
      } else {
        return null;
      }
    case "registered":
      if (!userRecord) {
        throw new Error(
          "Getting experiment condition for registered user with no user record",
        );
      }

      if (enrollIfNecessary) {
        return getConditionOrEnrollUser(
          _getCurrentUserOrThrow().uid,
          experimentName,
          userRecord,
          async (experimentName, condition) => {
            cloudFunctionProxy
              .enrollInExperiment({
                experimentName,
                condition: condition as any, // Typescript can't parameterize the generic here through the cloud proxy.
              })
              .then(() => {
                return;
              })
              .catch(error =>
                console.error(
                  `Couldn't record enrollment in ${experimentName} for condition ${condition}: ${error}`,
                ),
              );
          },
        );
      } else {
        return getEnrolledConditionForUser(experimentName, userRecord);
      }
    case "pending":
      throw new Error(
        "Can't get experiment condition when user is still pending",
      );
  }
}

export function hasSeenCoachMarkID(
  coachMarkID: CoachMarkID,
  userRecord: UserRecord | null,
): boolean {
  return (
    (userRecord &&
      userRecord.seenCoachMarks &&
      userRecord.seenCoachMarks.includes(coachMarkID)) ||
    pendingSeenCoachMarks.has(coachMarkID)
  );
}

export function recordSeenCoachMarkID(
  coachMarkID: CoachMarkID,
  baseUserRecord: UserRecord | null,
  userState: UserState,
): UserRecord | null {
  switch (userState) {
    case "anonymous":
    case "pending":
      pendingSeenCoachMarks.add(coachMarkID);
      return null;
    case "registered":
      cloudFunctionProxy
        .markCoachMarkSeen({ coachMark: coachMarkID })
        .then(() => {
          console.log(`Recorded ${coachMarkID}`);
        })
        .catch(error => {
          console.error(`Failure recording ${coachMarkID}: ${error}`);
        });
      if (!baseUserRecord) {
        throw new Error(
          "Signed in but attempting to record seen coach mark without user record",
        );
      }
      return {
        ...baseUserRecord,
        seenCoachMarks: [
          ...new Set([
            ...((baseUserRecord && baseUserRecord.seenCoachMarks) || []),
            coachMarkID,
          ]),
        ],
      };
    default:
      throw unreachableCaseError(userState);
  }
}

export const recordReviewMarking = ({
  cardID,
  promptIndex,
  reviewMarking,
  oldCardState,
  newCardStateOrderSeed,
  reviewDate,
  essayName,
  essayVersion,
  sessionID,
}: {
  cardID: string;
  promptIndex: number | null;
  reviewMarking: ReviewMarking;
  oldCardState: CardState | null;
  newCardStateOrderSeed: number;
  reviewDate: Date;
  essayName: string | null;
  essayVersion: number | null;
  sessionID: number | null;
}) => {
  const currentUser = firebase.auth().currentUser;
  _markCard(
    {
      cardID,
      promptIndex,
      reviewMarking,
      newCardStateOrderSeed,
      essayName,
      essayVersion,
      sessionID,
      dueTimestamp: oldCardState && oldCardState.dueTime,
      reviewDate,
      deviceType: isTouch() ? "mobile" : "desktop",
      reviewMarkingMethod: "button",
    },
    currentUser && currentUser.uid,
  ).catch(error => console.error("Card marking error: ", error));
};

export const getAggregateSessionRecords = async (): Promise<AggregateSessionRecord[]> => {
  const sessionsReference = _getUserReference(
    _getCurrentUserOrThrow().uid,
  ).collection("sessions");
  const sessions = (await sessionsReference.get()).docs.map(
    doc => doc.data() as AggregateSessionRecord,
  );
  sessions.sort(
    (a, b) => a.startingTimestamp.toMillis() - b.endingTimestamp.toMillis(),
  );
  return sessions;
};

export const signOut = () => firebase.auth().signOut();

export const unsubscribeFromNotifications = async () => {
  try {
    const result: {
      success: boolean;
    } = (
      await firebase.functions().httpsCallable("unsubscribeFromNotifications")()
    ).data;
    return result.success;
  } catch (error) {
    console.error("Couldn't unsubscribe: ", error);
    return false;
  }
};

export const signInWithMagicID = async (magicID: string) => {
  if (getCurrentUserState() !== "registered") {
    const token: string = (
      await firebase.functions().httpsCallable("requestAuthToken")(magicID)
    ).data;
    await firebase.auth().signInWithCustomToken(token);
  }
};

const _getEssayCollectionReference = () => _getDatabase().collection("essays");

const _getCurrentEssayVersionQuery = (
  essayDocument: firebase.firestore.DocumentReference,
) =>
  essayDocument
    .collection("versions")
    .orderBy("registrationDate", "desc")
    .limit(1);

export const getNewCardID = () => _getEssayCollectionReference().doc().id;

export type CardIDsByEssay = Record<EssayID, string[]>;
export type EssayVersions = Record<EssayID, number>;
export type CardDataByCardID = Map<string, CardData>;

export async function getAllCardData(): Promise<{
  cardIDsByEssay: CardIDsByEssay;
  cardDataByCardID: CardDataByCardID;
  essayVersions: EssayVersions;
}> {
  const cardIDsByEssay = {} as CardIDsByEssay;
  const cardDataByCardID: CardDataByCardID = new Map();
  const essayVersions = {} as EssayVersions;
  const essayCardDatas = await Promise.all(
    orderedEssayIDs.map(async essayID => {
      // TODO: have some pointer for "latest" so we can skip this step
      const essayVersionsSnapshot = await _getCurrentEssayVersionQuery(
        _getEssayCollectionReference().doc(essayID),
      ).get();
      essayVersions[essayID] = Number.parseInt(
        essayVersionsSnapshot.docs[0]?.id || "-1",
      );
      const cards = essayVersionsSnapshot.empty
        ? []
        : (
            await essayVersionsSnapshot.docs[0].ref
              .collection("cards")
              .orderBy("essayOrder", "asc")
              .get()
          ).docs.map(snapshot => {
            const cardData = snapshot.data();
            delete cardData["essayOrder"];
            return cardData as CardData;
          });
      return { essayID: essayID as EssayID, cards };
    }),
  );

  for (const { essayID, cards } of essayCardDatas) {
    cardIDsByEssay[essayID] = cards.map(cardData => cardData.cardID);
    cards.forEach(cardData => {
      // validate cards more carefully, probably
      if (!(cardData as any).cardType) {
        // TODO: this is a bit of a hack. Ideally we can trust the server data.
        cardData.cardType = "basic";
      }
      cardDataByCardID.set(cardData.cardID, cardData);
    });
  }
  return { cardIDsByEssay, cardDataByCardID, essayVersions };
}

export const logDidContinueReading = () => {
  firebase.functions().httpsCallable("didContinueReading")();
};

export const debugCollectAllCards = async (params?: URLSearchParams) => {
  const user = _getCurrentUserOrThrow();
  const uid = (params && params.get("uid")) || user.uid;
  const targetEssay = params && params.get("essay");
  console.log(`Collecting all cards for ${uid} on essay ${targetEssay}`);

  const essays = await _getEssayCollectionReference().get();
  const reviewDate = new Date();
  for (const essay of essays.docs) {
    if (targetEssay && essay.id !== targetEssay) {
      continue;
    }
    console.log("Collecting cards for ", essay.id);

    const versions = await _getCurrentEssayVersionQuery(essay.ref).get();
    const cards = await versions.docs[0].ref.collection("cards").get();
    for (const doc of cards.docs) {
      const cardData = doc.data() as CardData;
      const result = await _markCard(
        {
          cardID: cardData.cardID,
          promptIndex: cardData.cardType === "basic" ? null : 0,
          reviewMarking: "remembered",
          essayName: essay.id,
          newCardStateOrderSeed: Math.random(),
          essayVersion: Number.parseInt(versions.docs[0].id),
          dueTimestamp: null,
          sessionID: null,
          reviewDate,
          reviewMarkingMethod: null,
          deviceType: null,
        },
        uid,
      );
      console.log(`Marked ${cardData.cardID}: ${result}`);
    }
  }
};

export const debugMakeAllCardsDueNow = async () => {
  const user = _getCurrentUserOrThrow();
  const cards = await _getCardsReference(user.uid).get();
  const batch = _getDatabase().batch();
  cards.docs.forEach(doc => {
    batch.set(
      doc.ref,
      { dueTime: firebase.firestore.Timestamp.now() },
      { merge: true },
    );
  });
  await batch.commit();
};

export const debugMakeOneCardDueNow = async () => {
  const user = _getCurrentUserOrThrow();
  const cards = await _getCardsReference(user.uid).get();
  const batch = _getDatabase().batch();
  cards.docs.forEach((doc, index) => {
    batch.set(
      doc.ref,
      {
        dueTime: firebase.firestore.Timestamp.fromMillis(
          Date.now() + (index === 0 ? 0 : 60 * 60 * 1000),
        ),
      },
      { merge: true },
    );
  });
  await batch.commit();
};

export const debugReset = async () => {
  const user = _getCurrentUserOrThrow();
  const cards = await _getCardsReference(user.uid).get();
  const batch = _getDatabase().batch();
  cards.docs.forEach(doc => batch.delete(doc.ref));
  await batch.commit();
};

export const disconnectIfSSR = async () => {
  if (isViewingForSSRSnapshot()) {
    unsubscribeAuthStateChange && unsubscribeAuthStateChange();
    await _getDatabase().disableNetwork();
  }
};
