/* tslint:disable:no-import-side-effect */
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

import * as firebase from "firebase/app";

/* tslint:enable:no-import-side-effect */
import { AuthenticatedAPI } from "../functions/src/api/types";

type APITypes = typeof import("../functions/src/api");

type CloudFunctionProxy = {
  [FunctionName in keyof APITypes]: APITypes[FunctionName] extends AuthenticatedAPI<
    infer Params,
    infer Result
  >
    ? (...params: [Params] extends [never] ? [] : [Params]) => Promise<Result>
    : never;
};

const cloudFunctionProxy = new Proxy(
  {},
  {
    get: function<FunctionName extends keyof CloudFunctionProxy>(
      _: unknown,
      functionName: FunctionName,
    ): CloudFunctionProxy[FunctionName] {
      return (async (params: any) => {
        const result = await firebase.functions().httpsCallable(functionName)(
          params,
        );
        return result.data;
      }) as CloudFunctionProxy[FunctionName];
    },
  },
) as CloudFunctionProxy;

export default cloudFunctionProxy;
