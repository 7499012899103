import { ExperimentName, ExperimentCondition } from "./types";
import experimentListing from "./experimentListing";

// Implementation of MurmurHash3, described at https://en.wikipedia.org/wiki/MurmurHash. Implementation lifted from https://stackoverflow.com/a/47593316.
function murmurHash3(str: string) {
  let h = 1779033703 ^ str.length;
  for (let i = 0; i < str.length; i++) {
    h = Math.imul(h ^ str.charCodeAt(i), 3432918353);
    h = (h << 13) | (h >>> 19);
  }
  h = Math.imul(h ^ (h >>> 16), 2246822507);
  h = Math.imul(h ^ (h >>> 13), 3266489909);
  return (h ^= h >>> 16) >>> 0;
}

const uint32Max = Math.pow(2, 32);

/**
 * Returns the experimental condition for a user given a set of conditions and their probabilities.
 *
 * @remarks For example: `getConditionForUserID("abcd", {newBehavior: 0.4, oldBehavior: 0.6})`
 *
 * @param userID - A Firebase userID. Our approach takes advantage of the fact that Firebase user IDs are assigned uniformly over lexicographic space, so make sure that's maintained.
 * @param experimentName - The name of the experiment as specified in experimentListing.ts.
 * @returns One of the keys of `conditions`.
 */
function getConditionForUserID<EN extends ExperimentName>(
  userID: string,
  experimentName: EN,
): ExperimentCondition<EN> {
  // We shouldn't need to help Typescript out like this, but I guess we do.
  const conditions = (experimentListing[experimentName]
    .conditions as unknown) as Record<ExperimentCondition<EN>, number>;
  const conditionNames = Object.keys(conditions) as ExperimentCondition<EN>[];

  // First, let's check to make sure the probabilities add to 1.
  if (
    Math.abs(
      1.0 - conditionNames.reduce((sum, key) => sum + conditions[key], 0),
    ) > 0.0001
  ) {
    throw new Error(`Condition probabilities don't add to 1: ${conditions}`);
  }

  const userPosition = murmurHash3(userID + experimentName);

  const sortedConditions = conditionNames.sort();
  let workingBucket = 0;
  for (const condition of sortedConditions) {
    workingBucket += conditions[condition] * uint32Max;
    if (userPosition < workingBucket) {
      return condition;
    }
  }

  throw new Error("Should be unreachable");
}

export default getConditionForUserID;
