import { CardStates } from "../db";
import getCardLimitForReviewSession from "./getCardLimitForReviewSession";
import getFuzzyDueTimeThreshold from "./getFuzzyDueTimeThreshold";

export default function getDueCardIDs({
  cardStates,
  timestampMillis,
  reviewSessionIndex,
  cardsCompletedInCurrentSession,
}: {
  cardStates: CardStates;
  timestampMillis: number;
  reviewSessionIndex: number;
  cardsCompletedInCurrentSession: number;
}) {
  const dueThresholdTimestamp = getFuzzyDueTimeThreshold(timestampMillis);
  const orderedDueCardIDs = Object.keys(cardStates).filter(
    cardID => cardStates[cardID].dueTime.toMillis() <= dueThresholdTimestamp,
  );

  const maxCardsInSession = getCardLimitForReviewSession(reviewSessionIndex);
  const cardsRemaining = Math.max(
    0,
    maxCardsInSession - cardsCompletedInCurrentSession,
  );

  return (
    orderedDueCardIDs
      // Prefer lower-level cards when choosing the subset of questions to review.
      .sort((a, b) => {
        if (cardStates[a].interval === cardStates[b].interval) {
          return (
            (cardStates[a].orderSeed || 0) - (cardStates[b].orderSeed || 0)
          );
        } else {
          return cardStates[a].interval - cardStates[b].interval;
        }
      })
      // Apply our review cap.
      .slice(0, cardsRemaining)
  );
}
