import { UserRecord } from "../db";
import { ExperimentCondition, ExperimentName } from "./types";

/**
 * Given a user's record, returns their current condition for the specified experiment, if enrolled.
 */
export default function<EN extends ExperimentName>(
  experimentName: EN,
  userRecord: UserRecord,
): ExperimentCondition<EN> | null {
  const existingCondition =
    (userRecord.experimentConditions &&
    userRecord.experimentConditions[experimentName]) || null;

  if (existingCondition) {
    return existingCondition as ExperimentCondition<EN>; // Cast shouldn't be necessary, but Typescript's having trouble with the inference.
  } else {
    return null;
  }
}
