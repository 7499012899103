// This file lists all the experiments we're running.

interface ExperimentEnrollmentFilters {
  registeredAfter?: Date;
}

interface ExperimentSpecification {
  filters?: ExperimentEnrollmentFilters;
  conditions: Record<string, number>;
}

const experimentListing = {
  aggressiveStart: {
    filters: { registeredAfter: new Date("2019-08-03T06:45:00-07:00") },
    conditions: { control: 0, experimental: 1.0 },
  },
  efficacy2: {
    filters: { registeredAfter: new Date("2020-01-27T09:45:00-08:00") },
    conditions: {
      control: 1.0,
      delay5Days: 0.0,
      delay1Month: 0.0,
    },
  },
  schedules202104: {
    filters: { registeredAfter: new Date("2020-04-20T00:00:00-08:00") },
    conditions: {
      control: 0.0, // Not sure why this is necessary: the type checker seems to demand it.
      "2021-04-A": 1.0,
      "2021-04-B": 0.0,
      "2021-04-C": 0.0,
      "2021-04-D": 0.0,
      "2021-04-AX": 0.0,
      "2021-04-BX": 0.0,
      "2021-04-CX": 0.0,
      "2021-04-DX": 0.0,
    },
  },
  labelApplicationPrompts: {
    conditions: {
      control: 0.5,
      experimental: 0.5,
    },
  },
};

type ExperimentListingConstraint = Record<string, ExperimentSpecification>;
export default ((experimentListing as ExperimentListingConstraint) as typeof experimentListing) as {
  [EN in keyof typeof experimentListing]: {
    filters?: ExperimentEnrollmentFilters;
    conditions: Record<
      keyof typeof experimentListing[EN]["conditions"],
      number
    >;
  };
};
