import { UserRecord } from "../db";
import getConditionForUserID from "./getConditionForUserID";
import { ExperimentCondition, ExperimentName } from "./types";

type EnrollInExperiment = <EN extends ExperimentName>(
  experimentName: EN,
  condition: ExperimentCondition<EN>,
) => Promise<void>;

/**
 * Given a user and their state, returns their condition for the specified experiment. If the user has never interacted with this experiment before, they'll be enrolled server-side.
 */
export default function<EN extends ExperimentName>(
  userID: string,
  experimentName: EN,
  userRecord: UserRecord,
  enrollInExperiment: EnrollInExperiment,
): ExperimentCondition<EN> {
  const existingCondition =
    userRecord.experimentConditions &&
    userRecord.experimentConditions[experimentName];
  if (existingCondition) {
    // Typescript really should be able to prove that this is the right type, but it can't for some reason.
    return (existingCondition as unknown) as ExperimentCondition<EN>;
  }

  // They haven't been enrolled yet. Let's make that happen.
  const condition = getConditionForUserID(userID, experimentName);

  // Tell the server, so that the user's experience will be stable even if we change the condition proportions later.
  enrollInExperiment(experimentName, condition).catch(error =>
    console.error(
      `Couldn't enroll ${userID} in ${experimentName} under condition ${condition}: ${error}`,
    ),
  );

  // We don't wait on the server's reply to continue, though. We just assume it'll succeed.
  return condition;
}
