import "react-app-polyfill/stable";
import "katex";
import "katex/dist/katex.css";
import "./index.scss";

import * as H from "history";
import React from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import loadable from "@loadable/component";
import * as Sentry from "@sentry/browser";

import Analytics from "./routes/ReactRouterGA";
import * as serviceWorker from "./serviceWorker";
import { initializeDB } from "./db";
import GitInfo from "react-git-info/macro";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://de0e0234cfcc49f1b7c8cc92c57a7a37@sentry.io/1412759",
    release: "qcc@" + GitInfo().commit.hash,
    environment: "production",
    maxBreadcrumbs: 50,
  });
}

initializeDB();

class ErrorBoundary extends React.Component {
  componentDidCatch = (error: Error | null) =>
    Sentry.captureException(error || new Error("Error was swallowed"));
  render = () => this.props.children;
}

const AttemptRedirectWithAccessCode = loadable(() =>
  import("./routes/AttemptRedirectWithAccessCode"),
);
const DebugRoute = loadable(() => import("./routes/DebugRoute"));
const EssayRoute = loadable(() => import("./routes/EssayRoute"));
const FrontPageRoute = loadable(() => import("./routes/FrontPageRoute"));
const LandingRoute = loadable(() => import("./routes/Landing"));
const LoginRoute = loadable(() => import("./routes/Login"));
const StudyRoute = loadable(() => import("./routes/StudyRoute"));
const TermsRoute = loadable(() => import("./routes/Terms"));
const UnsubscribeRoute = loadable(() => import("./routes/Unsubscribe"));

const AppRouter = () => (
  <HelmetProvider>
    <Router>
      <Analytics id="UA-132772498-1">
        <Switch>
          <Route
            path="/landing"
            render={params => {
              if (params.location.search) {
                const searchParams = new URLSearchParams(
                  params.location.search,
                );
                const accessCode = searchParams.get("access");
                const essay = searchParams.get("essay");
                if (accessCode) {
                  return (
                    <LandingRoute
                      accessCode={accessCode}
                      essay={essay || "qcvc"}
                    />
                  );
                }
              }
              return <Redirect to="/login" />;
            }}
          />

          <Route path="/login" component={LoginRoute} />
          <Route path="/signin" component={LoginRoute} />
          <Route path="/review" component={StudyRoute} />
          <Route
            path="/study"
            component={({ location }: { location: H.Location }) => (
              <Redirect to={{ ...location, pathname: "/review" }} />
            )}
          />

          <Route path="/terms" component={TermsRoute} />
          <Route path="/unsubscribe" component={UnsubscribeRoute} />

          <Route path="/debug" component={DebugRoute} />

          <Route
            path="/ucla/:essay"
            render={props => (
              <Redirect to={`/${props.match.params.essay}?ucla`} />
            )}
          />

          <Route
            path="/:essay/"
            exact
            strict
            render={props => {
              const { location, match } = props;
              if (location.search) {
                const searchParams = new URLSearchParams(location.search);
                const accessCode = searchParams.get("access");
                if (accessCode) {
                  return (
                    <AttemptRedirectWithAccessCode
                      {...props}
                      originalPath={location.pathname}
                      accessCode={accessCode}
                    />
                  );
                } else {
                  return <EssayRoute essay={match.params.essay} {...props} />;
                }
              } else {
                return <Redirect to={`/${match.params.essay}`} />;
              }
            }}
          />
          <Route
            path="/:essay"
            exact
            render={props => (
              <EssayRoute essay={props.match.params.essay} {...props} />
            )}
          />

          <Route path="/" component={FrontPageRoute} />
        </Switch>
      </Analytics>
    </Router>
  </HelmetProvider>
);

const root = (
  <ErrorBoundary>
    <AppRouter />
  </ErrorBoundary>
);
const containerElement = document.getElementById("react-root")!;

if (containerElement.hasChildNodes()) {
  ReactDOM.hydrate(root, containerElement);
} else {
  ReactDOM.render(root, containerElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
