const essayMetadata = {
  qcvc: {
    title: "Quantum computing for the very curious",
    hasApplicationPrompts: false,
  },

  search: {
    title: "How the quantum search algorithm works",
    hasApplicationPrompts: false,
  },

  teleportation: {
    title: "How quantum teleportation works",
    hasApplicationPrompts: false,
  },

  qm: {
    title: "Quantum mechanics distilled",
    isRaw: true,
    hasApplicationPrompts: true,
  },
} as {
  [key: string]: {
    title: string;
    hasApplicationPrompts: boolean;
    hidden?: boolean;
    isRaw?: boolean;
  };
};

export default essayMetadata;

export const orderedEssayIDs = [
  "qcvc",
  "search",
  "teleportation",
  "qm",
] as const;
export type EssayID = typeof orderedEssayIDs[number];
